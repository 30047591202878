/* eslint-disable no-const-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import exifr from 'exifr';

export const toCamelCase = (text) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  return text?.substr(0, 1)?.toUpperCase() + text?.substr(1);
};
export function splitMulti(str, tokens) {
  const tempChar = tokens[0];
  str = str.toString();
  for (let i = 1; i < tokens.length; i++) {
    str = str.split(tokens[i]).join(tempChar);
  }
  str = str.split(tempChar);
  return str;
}

export const getFileNameFromPath = (url) => {
  const splitUrl = url.split('/');
  return splitUrl[splitUrl.length - 1];
};

export const findFileFormat = (type) => {
  let checkType = '';
  if (type.split('/')[1] === 'gif') {
    // eslint-disable-next-line prefer-destructuring
    checkType = type.split('/')[1];
  } else {
    // eslint-disable-next-line prefer-destructuring
    checkType = type.split('/')[0];
  }
  return toCamelCase(checkType);
};
export const getFileExtension = (name) => {
  return name.split('.')[1];
};
export const getFileName = (name) => {
  return name.split('.')[0];
};
export const getFileMetaData = async (file) => {
  try {
    const defaultOptions = {
      iptc: true
    };
    const responseData = await exifr.parse(file, defaultOptions);
    // eslint-disable-next-line no-console
    console.log('responseData', responseData);
    if (responseData?.Keywords)
      responseData.Keywords = splitMulti(responseData.Keywords, [',']);
    else if (responseData?.XPKeywords)
      responseData.Keywords = splitMulti(responseData.XPKeywords, [',']);
    return responseData;
  } catch (error) {
    return null;
  }
};
export const getBinaryFileData = async (file) => {
  const getFileArray = () => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const view = new DataView(event.target.result);
        console.log(view);
        resolve(event.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsArrayBuffer(file);
    });
  };
  const temp = await getFileArray(file);
  const fileb = new Uint8Array(temp);
  return fileb;
};

export const assetDetailThumbnail = (
  rtype,
  path,
  name,
  info,
  controls = false
) => {
  if (rtype === 1 || rtype === 5)
    return (
      <img
        src={path}
        alt={name}
        style={{
          width: `${info?.dimention?.w}px`
        }}
        loading="lazy"
      />
    );
  if (rtype === 2)
    return (
      <>
        <video className="asset-fit" controls={controls}>
          <track kind="captions" />
          <source src={path} />
          Your browser does not support the video tag.
        </video>
        <i className="cus-icons playbtn" />
      </>
    );
  if (rtype === 3)
    return (
      <div className="audio-details">
        <audio className="asset-fit" controls={controls}>
          <track kind="captions" />
          <source src={path} />
        </audio>

        <i className="cus-icons audioicon" />
      </div>
    );
  return null;
};

export const flattenTags = (asset) => {
  if (asset) {
    const assetTags = asset.tags || {};
    return [...assetTags.admin, ...assetTags.custom];
  }
  return [];
};

export const getType = (type) => {
  let cardParams = { label: 'Image', route: 'images' };
  if (type === 2) cardParams = { label: 'Video', route: 'videos' };
  if (type === 3) cardParams = { label: 'Audio', route: 'audios' };
  if (type === 4) cardParams = { label: 'LgVideo', route: 'topicVideos' };
  if (type === 5) cardParams = { label: 'Gif', route: 'gif' };
  return cardParams;
};

export const truncateText = (text, count = 10) => {
  try {
    const textLength = text.length;
    return `${text?.substr(textLength - count, textLength)}`;
  } catch (e) {
    console.log('error', e);
    return '';
  }
};

export const copyText = (text) => {
  navigator.clipboard.writeText(text);
};
export const embedData = (data) => {
  const {
    info: { dimention },
    path
  } = data;
  const embedHtml = `<iframe width='${dimention?.w || 0}' height='${
    dimention?.h || 0
  }' src="${path}"/>`;
  return embedHtml;
};

export const timeout = (delay) => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((res) => setTimeout(res, delay));
};
// eslint-disable-next-line consistent-return
export const getMidjPath = (envn) => {
  switch (envn) {
    case 'local':
      return 'http://localhost:3000/api/resources';
    case 'prodip':
      return 'http://54.196.28.111:3000/api/generaterimg';
    case 'live':
      return 'https://api.ceauthor.com/cealmdjrny/api/generaterimg';
    default:
      break;
  }

  // eslint-disable-next-line no-promise-executor-return
};
// Utility function to get rights
export const getRights = (userInfo, values) => {
  let rights = [];

  // Check if the user's role is 5 and return the corresponding rights
  if (userInfo?.user?.role === 5) {
    rights = [userInfo?.user?.clients?.[0]?.id];
  } else if (values?.clientAsset) {
    // If clientAsset exists, use it for rights
    rights = [values.clientAsset];
  } else {
    // If no clientAsset, fallback to rights
    rights = [values.rights];
  }

  // If globalAsset is true, add 0 to the rights array
  if (values?.globalAsset === true) {
    rights.push(0);
  }

  return rights;
};
