/* eslint-disable no-unused-vars */
import { node } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from '../../components/AppHeader/Header';
import SideBar from '../../components/AppSidebar/Sidebar';
import Toolbar from '../../components/AppToolbar/Toolbar';
import * as assetsInfoAction from '../../redux/actions/assetsInfoAction';
import * as utilityInfoAction from '../../redux/actions/utilityInfoAction';
import CreateAsset from '../CreateAssset';
import TopicTolbar from '../../components/AppToolbar/TopicToolbar';

const AssetLayout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [classes, setClasses] = useState('');
  const {
    assetsInfo: { allAsset, assetTags, filters: filtersInfo },
    utilityInfo: { showFilteredData }
  } = useSelector((state) => state);
  const userInfo = useSelector((state) => state.userInfo);
  useEffect(() => {
    if (window.location.pathname.split('/').length <= 2 && !showFilteredData) {
      let assetState = 1;
      if (location.pathname.includes('videos')) assetState = 2;
      if (location.pathname.includes('audios')) assetState = 3;
      if (location.pathname.includes('gif')) assetState = 5;
      if (!allAsset?.[assetState]) {
        dispatch(
          assetsInfoAction.getAssetsByType({
            type: assetState,
            prevTag: assetTags,
            ...(userInfo?.user?.role === 5 && {
              clientid: userInfo?.user?.clients?.[0]?.id
            })
          })
        );
      } else if (filtersInfo?.searchText.length > 2) {
        const filtersInfoUpate = {
          ...filtersInfo,
          assetTypeFilter: assetState
        };
        dispatch(assetsInfoAction.advanceFilter(filtersInfoUpate));
      } else {
        dispatch(assetsInfoAction.filterDataByType(allAsset[assetState]));
      }
      dispatch(utilityInfoAction.updateAssetType(assetState));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    setClasses('');
    if (location.pathname.includes('wishlist')) {
      setClasses('wishlist-page');
    }
  }, [location.pathname]);

  return (
    <div className={`ce-assets-manager ${classes}`}>
      <Header />
      <SideBar />
      <div className="container-fluid ce-assets-container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12">
            {location.pathname.includes('topicVideos') ? (
              <TopicTolbar />
            ) : (
              <Toolbar />
            )}
            {children}
          </div>
        </div>
      </div>
      {!location.pathname.includes('topicVideos') && <CreateAsset />}
    </div>
  );
};
AssetLayout.propTypes = {
  children: node.isRequired
};

export default AssetLayout;
